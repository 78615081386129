@import "~antd/dist/antd.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-select-selector {
  /*height:300px;*/
  overflow: auto;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #0a1e96;
  color: #fff;
  cursor: pointer;
}

.ant-btn-primary {
  background-color: #0a1e96;
  border-color: #0a1e96;
}

.ant-btn-primary:hover {
  background-color: #096dd9;
  border-color: #096dd9;
}

.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu > .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected a,
.ant-menu > .ant-menu-item-selected a:hover {
  color: #000;
}

/*.a {
  background-color: #0a1e96;
  border-color: #0a1e96;
  color: #0a1e96 !important;
}

.ant-pagination-item-active {
  border-color: #0a1e96 !important;
  background-color: #fff !important;
}

.ant-pagination-item-active a {
  color: #0a1e96 !important;
}

.ant-pagination-item {
  color: #0a1e96 !important;
  background-color: #fff !important;
}

a {
  color: #000 !important;
}

a:hover {
  color: #0a1e96 !important;
}

a:hover {
  color: #0a1e96 !important;
}*/

.ant-select-multiple .ant-select-selector .ant-select-selection-item {
  /*background-color: #0a1e96;
  color: #fff;*/
}

.ant-select-multiple
  .ant-select-selector
  .ant-select-selection-item
  .ant-select-selection-item-remove {
  /*background-color: #0a1e96;
  color: #fff;*/
}

.spin-icon-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}